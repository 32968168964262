<div class="mx-3 xl:ml-0">
  <div class="bg-white rounded-lg p-2 lg:p-4 xl:p-4 mb-4">
    <!-- 欄位篩選區 -->
    <div class="box-border w-full py-1">
      <app-input-filters [configs]="inputs" [loading]="loading" (exportFormValues)="updateSelectedDate($event)"
        class="w-full"></app-input-filters>
    </div>
    <div class="box-border w-full">
      <app-multi-select-filters [data]="{ key: 'clients', type: 'multi-select', label: '客戶', options: clients}"
        [loading]="loading" (updateSelected)="updateSelectedClient($event)" class="w-full"></app-multi-select-filters>
    </div>

    <div class="w-full text-center">
      <button class="w-full lg:w-24 bg-primaryColor text-sm hover:bg-primaryHoverColor disabled:bg-primaryHoverColor text-white py-1 px-4 rounded"
        (click)="setPerformance()" [disabled]="loading">
        查詢
      </button>
    </div>
  </div>


  @if (!loading) {
  <!-- 可折疊的table -->
  @if(currentPerformance.length > 0) {
  <div class="mb-8">
    <!-- 當月業績 -->
    <div class="flex justify-between mb-1">
      <h2 class="text-dangerColor text-lg font-bold">
        {{selectedPerformanceFilter.fromYear}}-{{selectedPerformanceFilter.fromMonth}} ~
        {{selectedPerformanceFilter.toYear}}-{{selectedPerformanceFilter.toMonth}}</h2>
      <!-- <app-email></app-email> -->
    </div>
    <app-sales-table [data]="currentPerformance"></app-sales-table>
  </div>
  }

  @if(currentYearPerformance.length > 0) {
  <div class="my-8">
    <div class="flex justify-between mb-1">
      <h2 class="text-dangerColor text-lg font-bold">{{ currentYearPermanceFilter.fromYear}}-{{
        currentYearPermanceFilter.fromMonth}} ~ {{ currentYearPermanceFilter.toYear}}-{{
        currentYearPermanceFilter.toMonth}}
      </h2>
      <!-- <app-email></app-email> -->
    </div>
    <app-sales-table [data]="currentYearPerformance"></app-sales-table>
  </div>
  }


  @if(preYearPerformance.length > 0) {
  <div class="my-8">
    <div class="flex justify-between mb-1">
      <h2 class="text-dangerColor text-lg font-bold">{{ preYearPermanceFilter.fromYear}}-{{
        preYearPermanceFilter.fromMonth}}
        ~ {{ preYearPermanceFilter.toYear}}-{{preYearPermanceFilter.toMonth}}</h2>

      <!-- <app-email></app-email> -->
    </div>
    <app-sales-table [data]="preYearPerformance"></app-sales-table>
  </div>
  }

  }@else{
  <app-loading-spinner></app-loading-spinner>
  }

</div>
