@if(report && report.data.length > 0) {
  <div class="scroll-container mt-2" #tableContainer>
    <table class="table-dynamic text-sm text-left rtl:text-right w-full whitespace-nowrap">
      <thead #tableHeader class="td-primary-r-border">
        <tr class="text-sm">
          <th scope="col" class="sticky-col first-col min-w-28 font-black  px-2.5 py-2.5 lg:py-3 border-b border-tableBorderColor rounded-tl-lg bg-tableSecondary"></th>
          <th scope="col" class="sticky-col second-col min-w-28 py-2.5 px-2.5 lg:py-3 font-black border-b border-tableBorderColor td-primary-r-border text-right bg-tableSecondary"></th>
          @for(data of report.header; track $index; let i = $index) {
            <th scope="col" class="min-w-28 font-black  px-2.5 py-2.5 lg:py-3 border-b border-tableBorderColor td-primary-r-border text-right">{{data.year}}{{data.year !== '同期合計' && data.year !== '合計' ? '/' : ''}}{{data.month}}</th>
          }
        </tr>
      </thead>
      <tbody #tableContent>
        @for(place of report.data; track $index; let i = $index) {
          @if(place.rowspan > 1){
            @if(report.showBudget) {
              <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0}">
                <td class="sticky-col first-col py-2.5 px-2.5  min-w-28 lg:py-3"
                  [ngClass]="{'bg-tablePrimary text-white': report.data.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && report.data.length !== (i+1)}"></td>
                <td class="sticky-col second-col lg:py-3 py-2.5 px-2.5 td-primary-b-border  min-w-28"
                  [ngClass]="{'bg-tablePrimary text-white': report.data.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && report.data.length !== (i+1)}">預算</td>
                @for(data of place.data; track $index; let j = $index){
                  <td class="lg:py-3 py-2.5 px-2.5 td-primary-b-border td-primary-r-border text-right"
                    [ngClass]="{'bg-tablePrimary text-white': j === (place.data.length -1 ) || report.data.length === (i+1)}">
                    {{ data.currBudgetAmount | number:'1.0-0' }}
                  </td>
                }
              </tr>
            }
            <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0}">
              <td class="sticky-col first-col py-2.5 px-2.5  min-w-28 lg:py-3"
                [ngClass]="{'bg-tablePrimary text-white': report.data.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && report.data.length !== (i+1)}"></td>
              <td class="sticky-col second-col lg:py-3 py-2.5 px-2.5 td-primary-b-border min-w-28"
                [ngClass]="{'bg-tablePrimary text-white': report.data.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && report.data.length !== (i+1)}">實際金額</td>
              @for(data of place.data; track $index; let j = $index){
                <td class="lg:py-3 py-2.5 px-2.5 td-primary-b-border td-primary-r-border text-right min-w-28"
                  [ngClass]="{'bg-tablePrimary text-white': j === (place.data.length -1 ) || report.data.length === (i+1)}">
                  {{ data.currSalesAmount | number:'1.0-0' }}
                </td>
              }
            </tr>
            @if(report.showBudget) {
              <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0}">
                <td class="sticky-col first-col py-2.5 px-2.5  min-w-28 lg:py-3"
                  [ngClass]="{'bg-tablePrimary text-white': report.data.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && report.data.length !== (i+1)}"></td>
                <td class="sticky-col second-col lg:py-3 py-2.5 px-2.5 td-primary-b-border  min-w-28"
                  [ngClass]="{'bg-tablePrimary text-white': report.data.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && report.data.length !== (i+1)}">達成率</td>
                @for(data of place.data; track $index; let j = $index){
                  <td class="lg:py-3 py-2.5 px-2.5 td-primary-b-border td-primary-r-border text-right min-w-28"
                    [ngClass]="{'bg-tablePrimary text-white': j === (place.data.length -1 ) || report.data.length === (i+1)}">
                    {{ data.progressRate | customPercent }}
                  </td>
                }
              </tr>
            }
            <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0}">
              <td class="sticky-col first-col py-2.5 px-2.5 min-w-28 font-bold text-base lg:py-3"
                [ngClass]="{'bg-tablePrimary text-white': report.data.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && report.data.length !== (i+1)}">{{ place.rowName }}</td>
              <td class="sticky-col second-col lg:py-3 py-2.5 px-2.5 td-primary-b-border  min-w-28"
                [ngClass]="{'bg-tablePrimary text-white': report.data.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && report.data.length !== (i+1)}">同期比較</td>
              @for(data of place.data; track $index; let j = $index){
                <td class="lg:py-3 py-2.5 px-2.5 td-primary-b-border td-primary-r-border text-right min-w-28"
                  [ngClass]="{'bg-tablePrimary text-white': j === (place.data.length -1 ) || report.data.length === (i+1)}">
                  {{ data.preSalesAmount | number:'1.0-0' }}
                </td>
              }
            </tr>
            <tr class="border-b-2 border-tableBorderColor" [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0}">
              <td class="sticky-col first-col py-2.5 px-2.5 min-w-28 lg:py-3"
                [ngClass]="{'bg-tablePrimary text-white': report.data.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && report.data.length !== (i+1)}"></td>
              <td class="sticky-col second-col lg:py-3 py-2.5 px-2.5 td-primary-b-border td-primar min-w-28"
                [ngClass]="{'bg-tablePrimary text-white': report.data.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && report.data.length !== (i+1)}">成長率</td>
              @for(data of place.data; track $index; let j = $index){
                <td class="lg:py-3 py-2.5 px-2.5 td-primary-b-border td-primary-r-border text-right min-w-28"
                  [ngClass]="{'bg-tablePrimary text-white': j === (place.data.length -1 ) || report.data.length === (i+1)}">
                  {{ data.growthRate| customPercent }}
                </td>
              }
            </tr>
          }
        }
      </tbody>
    </table>
  </div>
}
