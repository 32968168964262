<div class="flex" >
  <div  [ngClass]="{'lg:hidden': sidebarVisible, 'relative hidden lg:block': !sidebarVisible}"
  class="bg-[#fbfbfb] text-white transition-all duration-300 ease-in-out"
  style="min-width: 245px; z-index: 1001;">
  <app-sidebar
   >
  </app-sidebar>
  </div>

  <div class="relative" style="width: 100%; overflow-x: hidden;">
    <app-header></app-header>
    <app-breadcrumb></app-breadcrumb>
    <router-outlet></router-outlet>
  </div>
</div>
