/**
 * SSO UserData
 *
 * @export
 * @class User
 */

export class User {
  EmployeeId: string;
  TwoFactorEnabled: string;
  email: string;
  name: string;
  nickname: string;
  preferred_username: string;
  role: string;
  sub: string;
}

/**
 * API Oberserve<T> StatusCode uppercase
 *
 * @export
 * @class Result<T>>
 */
export class Result<T> {
  Success: boolean;
  Message: string;
  Data: T;
}

/**
 * 欄位過濾條件
 *
 * @export
 * @class FilterConfig
 */
export class FilterConfig{
  key: string;
  type?: 'multi-select' | 'select-double' | 'select-single' | 'date' | 'choose-none' | 'checkbox';
  label: string;
  options?: any;
  defualtValue?: any;
}


/**
 * 過濾條件選項
 *
 * @export
 * @interface FilterConfigOptions
 */

export interface FilterConfigOptions {
  group?: string;
  value: any;
  name?: string;
  isSelected?: boolean;
}
