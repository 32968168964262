@if(reportType) {
  <div class="xl:ml-0 mb-3">
    <div class="bg-white rounded-lg p-2 lg:p-4 xl:p-4 mb-2">
      <div class="box-border w-full py-1">
        <app-input-filters [configs]="inputs" (exportFormValues)="updateSelectedDate($event)"
          class="w-full"></app-input-filters>
      </div>
      <div class="box-border w-full">
        <app-multi-select-filters [data]="singleDropdownSelectedValue"  (updateSelected)="updateSelectedType($event)"
        class="w-full"></app-multi-select-filters>
      </div>
      <div class="box-border w-full">
        <app-input-filters [configs]="showBudgetConfig" (exportFormValues)="updateBudgetStauts($event)"
          class="w-full"></app-input-filters>
      </div>
      <div class="w-full text-center">
        <button class="w-full lg:w-24 bg-primaryColor text-sm hover:bg-primaryHoverColor disabled:bg-primaryHoverColor text-white py-1 px-4 rounded"
        (click)="getYOYReport()" [disabled]="loading">
        查詢
      </button>
      </div>
    </div>
    @switch (reportType) {
    @case ('products') {
      @if(!loading){
        <app-single-revenuegrowthrate-table [report]="report"  ></app-single-revenuegrowthrate-table>
      }@else {
        <app-loading-spinner></app-loading-spinner>
      }
    }
    @case ('clients') {
      @if(!loading){
        <app-single-revenuegrowthrate-table [report]="report" ></app-single-revenuegrowthrate-table>
      }@else {
        <app-loading-spinner></app-loading-spinner>
      }
    }

    }

  </div>
}
