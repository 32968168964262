@if(tableData){
  <div class="table-container mt-2" #tableContainer>
    <table class="table-dynamic text-sm text-left rtl:text-right w-full whitespace-nowrap">
      <thead #tableHeader class="td-primary-r-border">
        <tr class="text-sm">
          <th scope="col" class="sticky-col first-col min-w-28 font-black px-2.5 py-2.5 lg:py-3 border-b border-tableBorderColor rounded-tl-lg bg-tableSecondary"></th>
          <th scope="col" class="sticky-col second-col min-w-28 px-2.5 py-2.5 lg:py-3font-black border-b border-tableBorderColor td-primary-r-border text-right bg-tableSecondary"></th>
          @for(product of header; track $index) {
            @if(product.productGroupName !== '全部') {
              <th scope="col" class="min-w-28 font-black px-2.5 py-2.5 lg:py-3 border-b border-tableBorderColor td-primary-r-border text-right"
                >
                {{ product.productGroupName }}
              </th>
            }
          }
        </tr>
      </thead>
      <tbody #tableContent>
        @for(place of tableData; track $index; let i = $index) {
          @if(place.rowspan > 1){
            @if(showBudget) {
              <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0}">
                <td class="sticky-col first-col py-2.5 px-2.5  lg:py-3 min-w-28"
                  [ngClass]="{'bg-tablePrimary text-white': tableData.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && tableData.length !== (i+1)}"></td>
                <td class="sticky-col second-col lg:py-3 py-2.5 px-2.5 td-primary-b-border min-w-28"
                  [ngClass]="{'bg-tablePrimary text-white': tableData.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && tableData.length !== (i+1)}">預算</td>
                @for(data of place.productsData; track $index; let j = $index){
                  <td class="lg:py-3 py-2.5 px-2.5 td-primary-b-border td-primary-r-border text-right"
                    [ngClass]="{'bg-tablePrimary text-white': j === (place.productsData.length -1) || tableData.length === (i+1)}">
                    {{ data.currBudgetAmount | number:'1.0-0' }}
                  </td>
                }
              </tr>
            }
            <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0}">
              <td class="sticky-col first-col py-2.5 px-2.5  min-w-28 lg:py-3"
                [ngClass]="{'bg-tablePrimary text-white': tableData.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && tableData.length !== (i+1)}"></td>
              <td class="sticky-col second-col lg:py-3 py-2.5 px-2.5 td-primary-b-border  min-w-28"
                [ngClass]="{'bg-tablePrimary text-white': tableData.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && tableData.length !== (i+1)}">實際金額</td>
              @for(data of place.productsData; track $index; let j = $index){
                <td class="lg:py-3 py-2.5 px-2.5 td-primary-b-border td-primary-r-border text-right min-w-28"
                  [ngClass]="{'bg-tablePrimary text-white': j === (place.productsData.length -1) || tableData.length === (i+1)}">
                  {{ data.currSalesAmount | number:'1.0-0' }}
                </td>
              }
            </tr>
            @if(showBudget) {
              <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0}">
                <td class="sticky-col first-col py-2.5 px-2.5  min-w-28 lg:py-3 "
                  [ngClass]="{'bg-tablePrimary text-white': tableData.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && tableData.length !== (i+1)}"></td>
                <td class="sticky-col second-col lg:py-3 py-2.5 px-2.5 td-primary-b-border  min-w-28"
                  [ngClass]="{'bg-tablePrimary text-white': tableData.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && tableData.length !== (i+1)}">達成率</td>
                @for(data of place.productsData; track $index; let j = $index){
                  <td class="lg:py-3 py-2.5 px-2.5 td-primary-b-border td-primary-r-border text-right min-w-28"
                    [ngClass]="{'bg-tablePrimary text-white': j === (place.productsData.length -1) || tableData.length === (i+1)}">
                    {{ data.progressRate | customPercent }}
                  </td>
                }
              </tr>
            }
            <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0}">
              <td class="sticky-col first-col py-2.5 px-2.5  min-w-28 font-bold text-base lg:py-3 "
                [ngClass]="{'bg-tablePrimary text-white': tableData.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && tableData.length !== (i+1)}">{{ place.placeName }}</td>
              <td class="sticky-col second-col lg:py-3 py-2.5 px-2.5 td-primary-b-border min-w-28"
                [ngClass]="{'bg-tablePrimary text-white': tableData.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && tableData.length !== (i+1)}">同期比較</td>
              @for(data of place.productsData; track $index; let j = $index){
                <td class="lg:py-3 py-2.5 px-2.5 td-primary-b-border td-primary-r-border text-right min-w-28"
                  [ngClass]="{'bg-tablePrimary text-white': j === (place.productsData.length -1) || tableData.length === (i+1)}">
                  {{ data.preSalesAmount | number:'1.0-0' }}
                </td>
              }
            </tr>
            <tr class="border-b-2 border-tableBorderColor" [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0}">
              <td class="sticky-col first-col py-2.5 px-2.5  min-w-28 lg:py-3 "
                [ngClass]="{'bg-tablePrimary text-white': tableData.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && tableData.length !== (i+1)}"></td>
              <td class="sticky-col second-col lg:py-3 py-2.5 px-2.5 td-primary-b-border  min-w-28"
                [ngClass]="{'bg-tablePrimary text-white': tableData.length === (i+1), 'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white': ((i+1) % 2) !== 0 && tableData.length !== (i+1)}">成長率</td>
              @for(data of place.productsData; track $index; let j = $index){
                <td class="lg:py-3 py-2.5 px-2.5 td-primary-b-border td-primary-r-border text-right min-w-28"
                  [ngClass]="{'bg-tablePrimary text-white': j === (place.productsData.length -1) || tableData.length === (i+1)}">
                  {{ data.growthRate | customPercent }}
                </td>
              }
            </tr>
          }
        }
      </tbody>
    </table>
  </div>
}
