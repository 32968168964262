@if(data) {
  <div>
    <label class="block mt-3 mb-1 text-primaryHoverColor text-sm font-bold" >{{data.label}}</label>
    @if(showDropDown) {
      @for (item of data.options; track $index; let i = $index) {
        <ul [ngClass]="{'inline-block': data.key === 'products', 'mb-3': data.key === 'clients' && (i+1) !== data.options?.length}" class="mb-1">
          <li class="text-gray-600 lg:inline-block text-sm mb-1 font-bold" [ngClass]="{ 'mr-2 min-w-7': data.key === 'clients' }">{{item.placeGroupName}}</li>
          @if (data.key === 'clients') {
            @for(place of item.placeVMs; track $index; let j = $index) {
              <li class="inline-block relative border mb-1 border-lineBorderColor  cursor-pointer" (change)="togglePlaceSelection(place)" [ngClass]="{'rounded-l-md': j === 0,'rounded-r-md': ((j + 1)=== item.placeVMs?.length), 'bg-transparent text-gray-600': !place.isSelected, 'bg-lineBorderColor border-r-white text-tablePrimary': place.isSelected, 'cursor-not-allowed': loading}" >
                <input type="checkbox" [id]="'checkbox-' + i + '-' + j" [value]="place.placeGroupCode" [checked]="place.isSelected" [disabled]="loading"
                       class="w-4 h-4 opacity-0 absolute t-0 b-0 r-0 l-0 cursor-pointer" >
                <label [for]="'checkbox-' + i + '-' + j"  class="inline-block text-sm font-medium cursor-pointer px-3 py-1 " >
                    {{ place.placeName }}
                </label>
            </li>

            }
          }@else if (data.key === 'products') {
            <li class="inline-block relative border mb-1 border-lineBorderColor  cursor-pointer"
              (change)="toggleProductSelection(item)"
              [ngClass]="{'rounded-l-md': i === 0,'rounded-r-md': ((i + 1)=== data.options?.length), 'bg-transparent text-gray-600': !item.isSelected, 'bg-lineBorderColor border-r-white text-tablePrimary': item.isSelected, 'cursor-not-allowed': loading}">
              <input type="checkbox" [id]="'checkbox-' + i" [value]="item.code" [checked]="item.isSelected" [disabled]="loading"
              class="w-4 h-4 opacity-0 t-0 b-0 r-0 l-0 cursor-pointer absolute">
            <label [for]="'checkbox-' + i" class="inline-block text-sm font-medium cursor-pointer px-3 py-1 ">
              {{ item.name }}
            </label>
            </li>
          }
        </ul>
      }
    }
  </div>
  <span class="inline-flex text-xs pb-2 text-gray-400 cursor-pointer" (click)="showDropDown = !showDropDown">
    {{showDropDown ? '收合': '展開'}}此選項<small>{{showDropDown ? '︿': '﹀'}}</small></span>
}
