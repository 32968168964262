import { Component } from '@angular/core';
import { MultiSelectFiltersComponent } from '../../../../../shared/components/multi-select-filters/multi-select-filters.component';
import { InputFiltersComponent } from '../../../../../shared/components/input-filters/input-filters.component';
import { LoadingSpinnerComponent } from '../../../../../shared/components/loading-spinner/loading-spinner.component';
import { MultiRevenuegrowthTableComponent } from '../multi-revenuegrowth-table/multi-revenuegrowth-table.component';
import { FilterConfig, FilterConfigOptions } from '../../../../../shared/shared.models';
import { ReportService } from '../../services/report.service';
import { forkJoin, map } from 'rxjs';
import { BaseProductGroups, PlaceGroupsWithPlaces, PlaceGroupsWithRevenueGrowth, Products, ReportFilter } from '../../report.models';

@Component({
  selector: 'app-multi-revenugrowthrate-display',
  standalone: true,
  imports: [MultiSelectFiltersComponent, MultiRevenuegrowthTableComponent, InputFiltersComponent, LoadingSpinnerComponent],
  templateUrl: './multi-revenugrowthrate-display.component.html',
  styleUrl: './multi-revenugrowthrate-display.component.scss'
})

export class MultiRevenugrowthrateDisplayComponent {

  productSelectedSetting: FilterConfig;
  productSelectedValue: FilterConfig;
  clientSelectedSetting: FilterConfig;
  clientSelectedValue: FilterConfig;
  dateSelectedSetting: FilterConfig[];

  years: FilterConfigOptions[];
  months: FilterConfigOptions[];

  filter: ReportFilter = {
    fromYear: new Date().getFullYear().toString(),
    fromMonth: (new Date().getMonth() + 1).toString().padStart(2, '0'),
    toYear: new Date().getFullYear().toString(),
    toMonth: (new Date().getMonth() + 1).toString().padStart(2, '0'),
    showBudget: false
  };
  showBudgetConfig: FilterConfig[];
  clients: PlaceGroupsWithPlaces[];
  products: Products[];
  originalRowData: PlaceGroupsWithRevenueGrowth[];
  rowData: PlaceGroupsWithRevenueGrowth[];
  tableData: any[];
  productsHeaders: BaseProductGroups[];
  originalProductsHeaders: BaseProductGroups[];
  loading: boolean = false;

  selectedProducts: { productGroupName: string, productGroupCode: string }[];

  constructor(
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.setOptions();
    this.setClients();
    this.setProducts();
  }

  //設定表單欄位
  setOptions() {
    this.reportService.getYears()
    forkJoin({
      years: this.reportService.getYears().pipe(
        map(y => y.map(y => ({ value: y })))),
      months: this.reportService.getMonths().pipe(
        map( m => m.map( m => ({ value: m })))),
    })
      .subscribe(({years, months}) => {
        this.years = years || [];
        this.months = months || [];
        this.dateSelectedSetting = [
          { key: 'fromYear', type: 'select-single', label: '年(起)', options: this.years, defualtValue: this.filter.fromYear},
          { key: 'fromMonth', type: 'select-single', label: '月(起)', options: this.months, defualtValue: this.filter.fromMonth},
          { key: 'toYear', type: 'select-single', label: '年(訖)', options: this.years, defualtValue: this.filter.toYear},
          { key: 'toMonth', type: 'select-single', label: '月(訖)', options: this.months, defualtValue: this.filter.toMonth},
        ];
        this.showBudgetConfig = [{ key: 'showBudget', type: 'checkbox', label: '預算', options: [ { name: '顯示', code: true, defualtValue: false}, {name: '不顯示', code: false}] }];
      });
  }

  //取得客戶(類別)資料 傳遞給multi-select-filters
  setClients() {
    this.reportService.getCustomersGroupDetails()
      .subscribe(async (data) => {
        this.clients = data;
        this.clients.forEach((item) => {
          item.placeVMs.forEach((place) => {
            place.isSelected = false;
           });
          item.placeVMs.unshift({ placeCode: 'ALL', placeName: '全部', placeGroupName: item.placeGroupName, isSelected: false })
        });
        this.clientSelectedValue = { key: 'clients', type: 'multi-select', label: '客戶', options: this.clients }
        this.filter.placeGroupCodes = [];
      });
  }

  // 取得產品(類別)資料 傳遞給multi-select-filters
  setProducts() {
    this.reportService.getProductCategories(false)
      .subscribe((data) => {
        data.forEach((product) => {
          product.isSelected = false;
        });
        this.products = data;
        this.productsHeaders = this.products.map(product => ({ productGroupName: product.name, productGroupCode: product.code }))
        .concat({ productGroupName: '總計', productGroupCode: 'SUM' });
        this.products.unshift({ code: 'ALL', name: '全部', isSelected: false });
        this.productSelectedValue = { key: 'products', type: 'multi-select', label: '產品', options: this.products };
      });
  }

  //轉換表格資料
  convertTableData(): void {
    this.tableData = this.rowData.map(place => {
      const productsData = this.productsHeaders.filter(item => item.productGroupName !== '全部')
      .map(product => {
        const data = place.datas.find(d => d.productGroupCode === product.productGroupCode );
        if (!data) {
          return {
            productCode: product.productGroupCode,
            productName: product.productGroupName,
            currSalesAmount: 0,
            currBudgetAmount: 0,
            preSalesAmount: 0,
            growthRate: null,
            progressRate: null,
            rangePreSalesAmount: 0
          };
        }
        return {
          productCode: product.productGroupCode,
          productName: data?.placeGroupName ?? '',
          currSalesAmount: data?.currSalesAmount,
          currBudgetAmount: data?.currBudgetAmount,
          preSalesAmount: data?.preSalesAmount,
          growthRate: data?.growthRate,
          progressRate: data?.progressRate,
          rangePreSalesAmount: data?.rangePreSalesAmount
        };
      });

      return {
        placeName: place.placeGroupName,
        productsData: productsData,
        rowspan: productsData.length,
        year: Number(this.filter.fromYear)
      };
    });
    this.loading = false;
  }

  //監聽日期更新
  updateDateSelected(value: any) {
    this.filter.fromYear = value.fromYear;
    this.filter.fromMonth = value.fromMonth;
    this.filter.toYear = value.toYear;
    this.filter.toMonth = value.toMonth;
  }

  //更新預算顯示
  updateBudgetStauts(value: {showBudget: boolean}){
    this.filter.showBudget = value.showBudget;
  }

  //監聽客戶更新
  updateClientSelected(item: any) {
    this.filter.placeGroupCodes = this.filterSelectedClients(item);
  }

  //監聽產品更新
  updateProductSelected(item: any) {
    this.filter.productGroupCodes = this.filterSelectedProducts(item);
    this.selectedProducts = item.filter((product: any) => product.isSelected)
    .map((product: any) => ({ productGroupName: product.name, productGroupCode: product.code }));
  }

  //篩選選取客戶
  filterSelectedClients(item: PlaceGroupsWithPlaces[]): string[] {
    const selectedPlaces: string[] = [];
    item.forEach((group) => {
      if (group.placeVMs.some((place) => place.placeCode === 'ALL' && place.isSelected)) {
        selectedPlaces.push(...group.placeVMs.map((place) => place.placeCode));
      } else {
        selectedPlaces.push(...group.placeVMs.filter((place) => place.isSelected).map((place) => place.placeCode));
      }
    });
    return selectedPlaces;
  }

  //篩選選取產品
  filterSelectedProducts(item: { code: string, name: string, isSelected: boolean }[]): string[] {
    const allSelected = item.some(product => product.code === 'ALL' && product.isSelected);

    if (allSelected) {
      return item.map(product => product.code);
    } else {
      return item.filter(product => product.isSelected).map(product => product.code);
    }
  }

  // 取得YOY報表API
  async getYOYReport() {
    this.loading = true;
    this.rowData = await this.reportService.takeProductAndClientsRevenueGrowth(this.filter);
    this.originalRowData = this.rowData;
    this.productsHeaders = this.selectedProducts.map((product: any) => ({ productGroupName: product.productGroupName, productGroupCode: product.productGroupCode }))
    .concat({ productGroupName: '總計', productGroupCode: 'SUM' });
    this.convertTableData();

  }
}
