import { Component, EventEmitter, Input, Output, OnDestroy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { FilterConfig } from '../../shared.models';

@Component({
  selector: 'app-input-filters',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './input-filters.component.html'
})
export class InputFiltersComponent implements OnDestroy {
  @Input() configs: FilterConfig[] = [];
  @Input() showBudgetconfigs: FilterConfig[] = [];
  @Output() exportFormValues = new EventEmitter<any>();
  @Input() loading = false;

  filterForm: FormGroup;
  defaultDate = new Date().toISOString().substring(0, 10);
  formSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.filterForm = this.fb.group({});
    this.setupFormSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loading'] && !changes['loading'].firstChange) {
      this.updateFormState();
    }

    if (changes['configs'] && changes['configs'].currentValue) {
      this.rebuildForm();
    }
  }

  // 偵測表單變化
  setupFormSubscription() {

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    this.formSubscription = this.filterForm.valueChanges.pipe(
      distinctUntilChanged((prev, curr) => {
        const result = JSON.stringify(prev) === JSON.stringify(curr);
        return result;
      })
    ).subscribe(value => {
      this.exportFormValues.emit(value);
    });
  }

  // 重建表單
  rebuildForm() {
    const newForm = this.fb.group({});

    this.configs.forEach(config => {
      let defaultValue = config.defualtValue || '';
      if (config.type === 'date' && !defaultValue) {
        defaultValue = this.defaultDate;
      }
      if (config.type === 'checkbox' && !defaultValue) {
        defaultValue = false;
      }

      newForm.addControl(
        config.key,
        this.fb.control(defaultValue, Validators.required)
      );
    });

    this.filterForm = newForm;
    this.setupFormSubscription();
    this.cdr.detectChanges();
  }

  // 更新表單狀態
  updateFormState() {
    if (this.loading && this.filterForm.enabled) {
      this.filterForm.disable();
    } else if (!this.loading && this.filterForm.disabled) {
      this.filterForm.enable();
    }
  }

  // 重置表單
  resetForm() {
    this.filterForm = this.fb.group({});
    this.setupFormSubscription();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
}
