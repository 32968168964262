  <div class="scroll-container mt-2" >
    <div class="table-wrapper border rounded-lg" #scrollContainer>
      <table class="table-dynamic scroll-area text-sm text-left rtl:text-right w-full whitespace-nowrap" >
        <thead class="bg-tableSecondary">
          <tr class="text-sm ">
            <th
              [ngStyle]="{'width': dynamicColWidth}" class="sticky left-0 min-w-32 z-10 lg:min-w-24 w-32 lg:w-24 font-medium lg:py-3  py-1.5 px-2.5 text-center td-primary-b-border  rounded-tl-lg"></th>
            <th [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium lg:py-3  py-1.5 px-2.5 text-right td-primary-b-border"  (click)="setSort('currBudgetAmount')" >
              <div class="flex justify-end items-center cursor-pointer">
                預算
                <svg class="w-3 h-3 text-[#9fb4da] ml-2" [ngClass]="{'rotate-180': sortColumn === 'currBudgetAmount' && sortOrder === 'desc'}"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                  <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
              </svg>
              </div>
            </th>
            <th [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium lg:py-3  py-1.5 px-2.5 text-right td-primary-b-border"  (click)="setSort('currSalesAmount')">
              <div class="flex justify-end items-center cursor-pointer">
                實際業績
                <svg class="w-3 h-3 text-[#9fb4da] ml-2" [ngClass]="{'rotate-180': sortColumn === 'currSalesAmount' && sortOrder === 'desc'}"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                  <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
              </svg>
              </div>
            </th>
            <th [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium lg:py-3  py-1.5 px-2.5 text-right td-primary-b-border " (click)="setSort('currDifference')">
              <div class="flex justify-end items-center cursor-pointer">
                差異
                <svg class="w-3 h-3 text-[#9fb4da] ml-2" [ngClass]="{'rotate-180': sortColumn === 'currDifference' && sortOrder === 'desc'}"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                  <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
              </svg>
              </div>
            </th>
            <th [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium lg:py-3  py-1.5 px-2.5 text-right td-primary-b-border " (click)="setSort('currProgressRate')">
              <div class="flex justify-end items-center cursor-pointer">
                達成率
                <svg class="w-3 h-3 text-[#9fb4da] ml-2" [ngClass]="{'rotate-180': sortColumn === 'currProgressRate' && sortOrder === 'desc'}"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                  <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
              </svg>
              </div>
            </th>
            <th [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium lg:py-3  py-1.5 px-2.5 text-right td-primary-b-border " (click)="setSort('preSalesAmount')">
              <div class="flex justify-end items-center cursor-pointer">
                去年同期
                <svg class="w-3 h-3 text-[#9fb4da] ml-2" [ngClass]="{'rotate-180': sortColumn === 'preSalesAmount' && sortOrder === 'desc'}"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                  <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
              </svg>
              </div>
            </th>
            <th [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium lg:py-3  py-1.5 px-2.5 text-right td-primary-b-border " (click)="setSort('preDifference')">
              <div class="flex justify-end items-center cursor-pointer">
                去年差異
                <svg class="w-3 h-3 text-[#9fb4da] ml-2" [ngClass]="{'rotate-180': sortColumn === 'preDifference' && sortOrder === 'desc'}"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                  <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
              </svg>
              </div>
            </th>
            <th [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium lg:py-3  py-1.5 px-2.5 text-right td-primary-b-border " (click)="setSort('growthRate')">
              <div class="flex justify-end items-center cursor-pointer">
                成長率
                <svg class="w-3 h-3 text-[#9fb4da] ml-2" [ngClass]="{'rotate-180': sortColumn === 'growthRate' && sortOrder === 'desc'}"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                  <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
              </svg>
              </div>
            </th>
            <th
              [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium lg:py-3  py-1.5 px-2.5 text-center td-primary-b-border  rounded-tr-lg" (click)="setSort('salesProportion')">
              <div class="flex justify-end items-center cursor-pointer">
                占比
                <svg class="w-3 h-3 text-[#9fb4da] ml-2" [ngClass]="{'rotate-180': sortColumn === 'salesProportion' && sortOrder === 'desc'}"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                  <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
              </svg>
              </div></th>
          </tr>
        </thead>
        <tbody>
          @for(item of data; track $index) {
          <tr (click)="toggleSubDetails(item)" class="bg-white cursor-pointer">
            <td [ngStyle]="{'width': dynamicColWidth}" class="sticky left-0 bg-white min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 td-primary-b-border font-bold">
              @if(shouldDisplayDetails(item)){
              <svg class="h-4 w-4 font-bol inline-block mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <polyline points="6 9 12 15 18 9" />
              </svg>
              } @else {
              <span class="px-3 inline-block"></span>
              }
              {{item.department}}
            </td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 td-primary-b-border  text-right">
              {{item.performance.currBudgetAmount ? (item.performance.currBudgetAmount | number:'1.0-0') : '∞'}}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 td-primary-b-border  text-right">
              {{item.performance.currSalesAmount ? (item.performance.currSalesAmount| number:'1.0-0') : '∞'}}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 td-primary-b-border  text-right">
              {{item.performance.currDifference ? (item.performance.currDifference | number:'1.0-0') : '∞'}}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 td-primary-b-border  text-right">
              {{item.performance.currProgressRate | customPercent}}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 td-primary-b-border  text-right">
              {{item.performance.preSalesAmount ? (item.performance.preSalesAmount  | number:'1.0-0') : '∞'}}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 td-primary-b-border  text-right">
              {{item.performance.preDifference ? (item.performance.preDifference  | number:'1.0-0') : '∞'}}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 td-primary-b-border  text-right">
              {{item.performance.growthRate | customPercent}}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 td-primary-b-border  text-right">
              {{item.performance.salesProportion ?? 0 | customPercent }}</td>
          </tr>
          @if(item.details.length > 0 && item.performance.isToggle) {
          <tr class="bg-gray-200 text-sm">
            <td colspan="9">
              <table class="w-full">
                <tbody>
                  @for(detail of item.details; track $index) {
                  @if(item.details.length > 0 ) {
                  <tr class="text-gray-700">
                    <td [ngStyle]="{'width': dynamicColWidth}" class="sticky left-0 bg-gray-200 min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 bg-tableLighter td-second-b-border font-bold ">
                      <span class="inline-block ml-9">{{detail.placeGroupName}}</span>
                    </td>
                    <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 bg-tableLighter td-second-b-border  text-right">
                      {{detail.currBudgetAmount ? (detail.currBudgetAmount| number:'1.0-0') : '∞'}}</td>
                    <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 bg-tableLighter td-second-b-border  text-right">
                      {{detail.currSalesAmount ? (detail.currSalesAmount| number:'1.0-0') : '∞'}}</td>
                    <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 bg-tableLighter td-second-b-border  text-right">
                      {{detail.currDifference ? (detail.currDifference | number:'1.0-0') : '∞'}}</td>
                    <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 bg-tableLighter td-second-b-border  text-right">
                      {{detail.currProgressRate | customPercent}}</td>
                    <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 bg-tableLighter td-second-b-border  text-right">
                      {{detail.preSalesAmount ? (detail.preSalesAmount | number:'1.0-0') : '∞'}}</td>
                    <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 bg-tableLighter td-second-b-border  text-right">
                      {{detail.preDifference  ? (detail.preDifference | number:'1.0-0') : '∞'}}</td>
                    <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 bg-tableLighter td-second-b-border  text-right">
                      {{detail.growthRate | customPercent}}</td>
                    <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 bg-tableLighter td-second-b-border  text-right">
                      {{detail.salesProportion ?? 0 | customPercent}}</td>
                  </tr>
                  }
                  }
                </tbody>
              </table>
            </td>
          </tr>
          }
          }
          <tr class="bg-tablePrimary font-bold text-white">
            <td [ngStyle]="{'width': dynamicColWidth}" class="sticky left-0 bg-tablePrimary min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 rounded-bl-lg"><span
                class="px-3 inline-block"></span>合計</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 text-right">{{sumPerformance.currBudgetAmount  ? (sumPerformance.currBudgetAmount| number:'1.0-0') : '∞' }}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 text-right">{{sumPerformance.currSalesAmount  ?  (sumPerformance.currSalesAmount| number:'1.0-0') : '∞' }}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 text-right">{{sumPerformance.currDifference ? (sumPerformance.currDifference| number:'1.0-0') : '∞'}}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 text-right">{{sumPerformance.currProgressRate| customPercent}}
            </td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 text-right">{{sumPerformance.preSalesAmount ? (sumPerformance.preSalesAmount| number:'1.0-0') : '∞'}}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 text-right">{{sumPerformance.preDifference ? (sumPerformance.preDifference| number:'1.0-0') : '∞'}}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 text-right">{{sumPerformance.growthRate | customPercent}}</td>
            <td [ngStyle]="{'width': dynamicColWidth}" class="min-w-32 lg:min-w-24 w-32 lg:w-24 lg:py-3  py-1.5 px-2.5 rounded-br-lg text-right">{{sumPerformance.salesProportion ?? 0 | customPercent}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
