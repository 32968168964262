import { Component, ElementRef, HostListener, Input, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import { BaseProductGroups } from '../../report.models';
import { CustomPercentPipe } from '../../../../../shared/pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-multi-revenuegrowth-table',
  standalone: true,
  imports: [CommonModule, CustomPercentPipe],
  templateUrl: './multi-revenuegrowth-table.component.html',
  styleUrl: './multi-revenuegrowth-table.component.scss'
})
export class MultiRevenuegrowthTableComponent {
  @Input() tableData: any[];
  @Input() header: BaseProductGroups[];
  @Input() showBudget: boolean;


  @ViewChild('tableHeader') tableHeader!: ElementRef;
  @ViewChild('tableContent') tableContent!: ElementRef;
  @ViewChild('tableContainer') tableContainer!: ElementRef;

  private originalHeaderTop: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tableData'] && changes['tableData'].currentValue) {
      this.setHeaderColumnWidths();
    }
  }
  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const thead = this.tableHeader.nativeElement;
    const content = this.tableContent.nativeElement;
    const headerRect = thead.getBoundingClientRect();
    const tableRect = this.tableContainer.nativeElement.getBoundingClientRect();

    if (headerRect.top <= 1 && tableRect.top < 0) {
      if (!thead.classList.contains('sticky')) {
        thead.classList.add('sticky');
        const headerHeight = thead.offsetHeight;
        content.style.paddingTop = `${headerHeight}px`;
        const table = this.tableContainer.nativeElement.querySelector('table');
        thead.style.width = `${table.offsetWidth}px`;
        thead.style.left = `${tableRect.left}px`;
        const scrollLeft = this.tableContainer.nativeElement.scrollLeft;
        this.updateNonStickyHeadersPosition(scrollLeft);
      }
    } else {
      if (thead.classList.contains('sticky')) {
        thead.classList.remove('sticky');
        content.style.paddingTop = '';
        thead.style.width = '';
        thead.style.left = '';

        const headerCells = thead.querySelectorAll('th');
        headerCells.forEach((cell: HTMLElement) => {
          cell.style.transform = '';
        });
      }
    }
  }

  private updateNonStickyHeadersPosition(scrollLeft: number) {
    const thead = this.tableHeader.nativeElement;
    const headerCells = thead.querySelectorAll('th:not(.sticky-col)');

    headerCells.forEach((cell: HTMLElement) => {
      cell.style.transform = `translateX(-${scrollLeft}px)`;
    });
  }

  ngAfterViewInit() {
    if (this.tableContainer) {
      this.setHeaderColumnWidths();
      this.tableContainer.nativeElement.addEventListener('scroll', this.handleTableScroll);

      if (typeof ResizeObserver !== 'undefined') {
        const observer = new ResizeObserver(() => {
          this.setHeaderColumnWidths();
        });
        observer.observe(this.tableContainer.nativeElement);
      }
    }
  }

  private handleTableScroll = (e: Event) => {
    const scrollLeft = (e.target as Element).scrollLeft;
    const thead = this.tableHeader.nativeElement;

    if (thead.classList.contains('sticky')) {
      this.updateNonStickyHeadersPosition(scrollLeft);
    }
  }

  private setHeaderColumnWidths() {
    const thead = this.tableHeader.nativeElement;
    const tbody = this.tableContent.nativeElement;
    const headerCells = thead.getElementsByTagName('th');
    const firstRowCells = tbody.querySelector('tr')?.getElementsByTagName('td');

    if (firstRowCells && firstRowCells.length > 0) {
      for (let i = 0; i < headerCells.length; i++) {
        if (firstRowCells[i]) {
          const width = firstRowCells[i].offsetWidth;
          headerCells[i].style.width = `${width}px`;
        }
      }
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.setHeaderColumnWidths();

    const thead = this.tableHeader.nativeElement;
    if (thead.classList.contains('sticky')) {
      const tableRect = this.tableContainer.nativeElement.getBoundingClientRect();
      const table = this.tableContainer.nativeElement.querySelector('table');
      thead.style.width = `${table.offsetWidth}px`;
      thead.style.left = `${tableRect.left}px`;
    }
  }

  ngOnDestroy() {
    if (this.tableContainer) {
      this.tableContainer.nativeElement.removeEventListener('scroll', this.handleTableScroll);
    }
  }
}
