<div class="xl:ml-0 mb-3">
  <div class="bg-white rounded-lg p-2 lg:p-4 xl:p-4">
    <div class="bbox-border w-full py-1">
      <app-input-filters [configs]="dateSelectedSetting" (exportFormValues)="updateDateSelected($event)"
        class="w-full"></app-input-filters>
    </div>
    <div class="box-border w-full">
      <app-multi-select-filters [data]="clientSelectedValue" (updateSelected)="updateClientSelected($event)" class="w-full" [loading]="loading"></app-multi-select-filters>
      <app-multi-select-filters [data]="productSelectedValue"  (updateSelected)="updateProductSelected($event)" class="w-full" [loading]="loading"></app-multi-select-filters>
    </div>
    <div class="box-border w-full">
      <app-input-filters [configs]="showBudgetConfig" (exportFormValues)="updateBudgetStauts($event)"
        class="w-full"></app-input-filters>
    </div>
    <div class="w-full text-center">
      <button class="w-full lg:w-24 bg-primaryColor text-sm hover:bg-primaryHoverColor disabled:bg-primaryHoverColor text-white py-1 px-4 rounded"
      (click)="getYOYReport()" [disabled]="loading">
      查詢
    </button>
  </div>
</div>
  @if(!loading) {
    <app-multi-revenuegrowth-table [tableData]="tableData" [header]="productsHeaders" [showBudget]="filter.showBudget ?? false"></app-multi-revenuegrowth-table>
  }@else {
    <app-loading-spinner></app-loading-spinner>
  }


</div>
