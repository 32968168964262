import { ChangeDetectorRef, Component } from '@angular/core';
import { SinglerevenuegrowthrateTableComponent} from '../single-revenuegrowthrate-table/single-revenuegrowthrate-table.component';
import { ActivatedRoute} from '@angular/router';
import { MultiSelectFiltersComponent } from '../../../../../shared/components/multi-select-filters/multi-select-filters.component';
import { InputFiltersComponent } from '../../../../../shared/components/input-filters/input-filters.component';
import { forkJoin, map, Subject, takeUntil} from 'rxjs';
import { ReportService } from '../../services/report.service';
import { FilterConfig, FilterConfigOptions } from '../../../../../shared/shared.models';
import { PlaceGroupsWithPlaces, PlaceGroupsWithRevenueGrowth, ProductGroupsWithRevenueGrowth, Products, ReportFilter, RevenueGrowthList } from '../../report.models';
import { LoadingSpinnerComponent } from '../../../../../shared/components/loading-spinner/loading-spinner.component';
@Component({
  selector: 'app-revenuegrowthrate-display',
  standalone: true,
  imports: [SinglerevenuegrowthrateTableComponent, MultiSelectFiltersComponent, InputFiltersComponent, LoadingSpinnerComponent],
  templateUrl: './revenuegrowthrate-display.component.html',
  styleUrl: './revenuegrowthrate-display.component.scss'
})
export class RevenuegrowthrateDisplayComponent {

  inputs: FilterConfig[];
  years: FilterConfigOptions[];
  months: FilterConfigOptions[];
  reportType: 'clients' | 'products-clients' | 'products' | string = '';
  loading: boolean = false;

  filter: ReportFilter = {
    fromYear: new Date().getFullYear().toString(),
    fromMonth: (new Date().getMonth() + 1).toString().padStart(2, '0'),
    toYear: new Date().getFullYear().toString(),
    toMonth: (new Date().getMonth() + 1).toString().padStart(2, '0'),
    showBudget: false
  };

  clients: PlaceGroupsWithPlaces[];
  products: Products[];

  singleDropdownSelectedValue: FilterConfig;
  showBudgetConfig: FilterConfig[];

  headers: {year: string, month: string}[]
  originalHeaders: {year: string, month: string}[]
  tableData: any[];
  rowData: any[];
  report: {data: RevenueGrowthList[], header: {year: string, month: string}[], showBudget: boolean};
  destroy$ = new Subject<void>();

  constructor(
    private activeRoute: ActivatedRoute,
    private reportService: ReportService,
    private cdr: ChangeDetectorRef
  ) {
  }

  async ngOnInit() {
    this.activeRoute.params
    .pipe(takeUntil(this.destroy$))
    .subscribe(params => {
      this.resetState();
      this.initByType(params['type']);
    });

  }

  //重置狀態
  private resetState() {
    this.loading = false;
    this.report = { data: [], header: [], showBudget: false };
    this.singleDropdownSelectedValue = new FilterConfig();
    this.filter = {
      fromYear: new Date().getFullYear().toString(),
      fromMonth: (new Date().getMonth() + 1).toString().padStart(2, '0'),
      toYear: new Date().getFullYear().toString(),
      toMonth: (new Date().getMonth() + 1).toString().padStart(2, '0'),
      showBudget: false
    }

  }

  //初始報表類型
  initByType(type: string) {
    this.reportType = type;

    switch (type) {
      case 'clients':
        this.initClients();
        break;
      case 'products':
        this.initProducts();
        break;
    }

    this.setOptions();
  }

  //初始化客戶
  initClients() {
    this.reportService.getCustomersGroupDetails(false)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.clients = this.processClientData(data);
          this.singleDropdownSelectedValue = {
            key: 'clients',
            type: 'multi-select',
            label: '客戶',
            options: this.clients
          };
          this.filter.placeGroupCodes = [];
          this.cdr.detectChanges();
        }
      });
  }

  //處理客戶資料
  processClientData(data: any[]) {
    return data.map(item => ({
      ...item,
      placeVMs: [
        { placeCode: 'ALL', placeName: '全部', placeGroupName: item.placeGroupName, isSelected: false },
        ...item.placeVMs.map((place: any) => ({ ...place, isSelected: false }))
      ]
    }));
  }

  //初始化產品
  initProducts() {
    this.reportService.getProductCategories(false)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.products = [
            { code: 'ALL', name: '全部', isSelected: false },
            ...data.map(product => ({ ...product, isSelected: false }))
          ];
          this.singleDropdownSelectedValue = {
            key: 'products',
            type: 'multi-select',
            label: '產品',
            options: this.products
          };
          this.filter.productGroupCodes = [];
          this.cdr.detectChanges();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //設定表單欄位
  setOptions() {
    this.reportService.getYears()
    forkJoin({
      years: this.reportService.getYears().pipe(
        map(y => y.map(y => ({ value: y })))),
      months: this.reportService.getMonths().pipe(
        map( m => m.map( m => ({ value: m })))),
    })
    .subscribe(({ years, months}) => {
      this.years = years || [];
      this.months = months || [];
      this.inputs = [
          { key: 'fromYear', type: 'select-single', label: '年(起)', options: this.years, defualtValue: this.filter.fromYear},
          { key: 'fromMonth', type: 'select-single', label: '月(起)', options: this.months, defualtValue: this.filter.fromMonth},
          { key: 'toYear', type: 'select-single', label: '年(訖)', options: this.years, defualtValue: this.filter.toYear},
          { key: 'toMonth', type: 'select-single', label: '月(訖)', options: this.months, defualtValue: this.filter.toMonth},
        ];
        this.showBudgetConfig = [{ key: 'showBudget', type: 'checkbox', label: '預算', options: [ { name: '顯示', code: true, defualtValue: false}, {name: '不顯示', code: false}] }];
      });
  }

  //更新日期篩選
  async updateSelectedDate(value: any){
    this.filter.fromYear = value.fromYear;
    this.filter.fromMonth = value.fromMonth;
    this.filter.toYear = value.toYear;
    this.filter.toMonth = value.toMonth;
  }

  //更新預算顯示
  updateBudgetStauts(value: {showBudget: boolean}){
    this.filter.showBudget = value.showBudget;
  }

  //更新下拉選單篩選
  async updateSelectedType(item: any[]){
    if (item) {
      switch (this.reportType) {
        case 'products': {
          this.filter.productGroupCodes = item.filter( detail => detail.isSelected === true).map( detail => detail.code);
          break;
        }
        case 'clients' : {
          this.filter.placeGroupCodes = this.filterSelectedClients(item);
          break;
        }
      }
    }
  }

  //篩選選取客戶
  filterSelectedClients(item: PlaceGroupsWithPlaces[]): string[]{
      const selectedPlaces: string[] = [];
      item.forEach((group) => {
        if (group.placeVMs.some((place) => place.placeCode === 'ALL' && place.isSelected)) {
          selectedPlaces.push(...group.placeVMs.map((place) => place.placeCode));
        } else {
          selectedPlaces.push(...group.placeVMs.filter((place) => place.isSelected).map((place) => place.placeCode));
        }
      });
      return selectedPlaces;
  }

  // 取得YOY報表API
  async getYOYReport(){
    switch (this.reportType) {
      case 'clients': {
        this.loading = true;
        let apiData = await this.reportService.takePlaceRevenueGrowth(this.filter);
        if (this.reportType !== 'clients') {
          return;
        }
        this.rowData = apiData;
        this.getMonthRange(apiData);
        this.convertTableData();
        break;
      }
      case 'products': {
        this.loading = true;
        let apiData = await this.reportService.takeProductRevenueGrowth(this.filter);
        if (this.reportType !== 'products') {
          return;
        }
        this.rowData = apiData;
        this.getMonthRange(apiData);
        this.convertTableData();
        break;
      }

    }


  }

  // 取得表頭的月份區間
  getMonthRange(apiData: PlaceGroupsWithRevenueGrowth[] |ProductGroupsWithRevenueGrowth[])  {
    const yearMonthMap = new Map();
    apiData.forEach(item => {
      item.datas.forEach(dataItem => {
        if (dataItem.year !== "CUR" && dataItem.year !== "SUM" && dataItem.month !== "CUR" && dataItem.month !== "SUM") {
          const key = `${dataItem.year}-${dataItem.month}`;
          if (!yearMonthMap.has(key)) {
            yearMonthMap.set(key, {
              year: dataItem.year,
              month: dataItem.month,
            });
          }
        }
      });
    });

    this.headers = Array.from(yearMonthMap.values()).sort((a, b) => {
      if (a.year === b.year) {
        return a.month - b.month;
      }
      return a.year - b.year;
    }).concat( { year: '合計', month: '' });
  }

  // 轉換表格資料
  convertTableData() {
    this.tableData = this.rowData.map(item => {
      const data = this.headers.map(header => {
        const row = item.datas.find((d: {year: string, month: string}) => (d.year === header.year && d.month === header.month) || (d.year === 'SUM' && d.month === 'SUM'));
        if (!row) {
          return {
            year: header.year,
            month: header.month,
            currSalesAmount: 0,
            currBudgetAmount: 0,
            preSalesAmount: 0,
            growthRate: null,
            progressRate: null,
          };
        }
        return {
          year: header.year,
          month: header.month,
          currSalesAmount: row?.currSalesAmount,
          preSalesAmount: row?.preSalesAmount,
          growthRate: row?.growthRate,
          currBudgetAmount: row?.currBudgetAmount,
          progressRate: row?.progressRate
        };
      });
      return {
        rowName: this.reportType === 'products' ? item.productGroupName: item.placeGroupName,
        data: data,
        rowspan: data.length
      };
    });
    this.report = { data: this.tableData, header: this.headers, showBudget: this.filter.showBudget ?? false };
    this.loading = false;
  }

}
